<script setup lang="ts">
import { ref, useSlots, computed, onMounted, defineEmits, watch } from 'vue';
import OCard from 'o365.vue.components.Card.vue';
import SearchInput from 'o365.vue.components.SearchInput.vue';
import OSort from 'o365.vue.components.Sort.vue';
import ActiveFilters from 'o365.vue.components.ActiveFilters.vue';
import OCardBodyCell from 'o365.vue.component.Card.BodyCell.vue';
import FilterItem from 'o365.modules.FilterItem.ts'
import { getDataColumnsFromProp, getDataColumnsFromSlot } from 'o365.controls.CardView.Columns.ts';
import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
import OMatchMedia from 'o365.vue.components.MatchMedia.vue';


const props = defineProps({
	dataObject: Object,
	linkParams: Object,
	/**
	* Columns passed as an object instead of slots
	*/
	columns: Array,
	/**
	* HeaderColumns passed as an object instead of slots
	*/
	headerColumns: Array,
	cardMinWidth: {
		type: String,
		default: "20rem"
	},
	cardGap: {
		type: String,
		default: "30px"
	},
	showImages: {
		type: Boolean
	},
	imagesView: {
		type: String
	},
	imagesPrimKeyColumnName: {
		type: String
	},
	noInfoAction: {
		type: Boolean
	},
	noFilter: {
		type: Boolean
	},
	noSort: {
		type: Boolean
	},
	noSearch: {
		type: Boolean
	},
	noAutoLoad: {
		type: Boolean
	},
	useSearchColumn: {
		type: Boolean,
		default: true
	},
	fixedHeightCards: {
		type: Boolean,
		default: true
	},
	multiselect: {
		type: Boolean
	},
	searchMask: {
		type: String
	},
	enableAddNew: {
		type: Boolean
	},
	searchMaskPlaceholder: {
		type: String
	},
	highlightCurrentRow: {
		type: Boolean
	},
	imageUrlParameters: {
		type: String,
		default: "mwidth=100"//&mheight=200"
	},
	getItemCategory: Function,
	rowClass: Function
});

const slots = useSlots();
const searchString = ref("");
const viewport = ref(null);
const selectedItem = ref(null);
const loading = ref(false);

const gridAutoRows = computed(() => props.fixedHeightCards ? '1fr' : 'auto');

const itemCategories = computed(() => {
	let data = props.dataObject?.data ?? [];
	if (!props.getItemCategory) {
		return [data];
	}

	let prevCategory = null;
	let categories = [];
	let currentCategory = [];

	for (let index in data) {
		let newCategory = props.getItemCategory(data[index]);
		if (newCategory != prevCategory) {
			prevCategory = newCategory;
			if (currentCategory.length) {
				categories.push(currentCategory);
				currentCategory = [];
			}
		}

		currentCategory.push(data[index]);
	}

	if (currentCategory.length) {
		categories.push(currentCategory);
	}

	return categories;
});

const dataColumns = getDataColumnsFromProp(props, "columns") ?? getDataColumnsFromSlot(slots, "default", props);
const headerDataColumns = getDataColumnsFromProp(props, "headerColumns") ?? getDataColumnsFromSlot(slots, "header", props);

const emit = defineEmits(['setCurrentItem', 'onCreateNew']);
watch(() => props.dataObject, newDataObject => {
	if (!props.noAutoLoad && newDataObject && !newDataObject.state.isLoaded && !newDataObject.state.isLoading) {
		newDataObject.load();
	}
}, { immediate: true });

function search(str) {
	searchString.value = str;
	loadDataObject();
}


function applySearchString() {
	if (props.useSearchColumn) {
		if (!searchString.value) {
			props.dataObject.filterObject.getItem("SearchColumn").selectedValue = null;
		} else {
			props.dataObject.filterObject.getItem("SearchColumn").operator = "contains";
			props.dataObject.filterObject.getItem("SearchColumn").selectedValue = searchString.value;
		}

		props.dataObject.filterObject.apply();
	} else {

		const allColumns = [...dataColumns.columns, ...(headerDataColumns?.columns ?? [])];
		const newFilterObject = new FilterItem({});
		newFilterObject.type = 'group';
		newFilterObject.mode = 'and';
		newFilterObject.items = [];
		const searchTerms = searchString.value.split(/\s+/).filter(v => v);

		for (let term of searchTerms) {
			const innerGroup = new FilterItem({});
			innerGroup.type = 'group';
			innerGroup.mode = 'or';
			innerGroup.items = [];

			for (let column of allColumns) {
				const expression = new FilterItem({ column: column.field });
				expression.operator = 'contains';
				expression.selectedValue = term;

				innerGroup.items.push(expression);
			}
			newFilterObject.items.push(innerGroup);
		}

		props.dataObject.filterObject.filterItems['SearchObject'] = newFilterObject;
		props.dataObject.filterObject.updateFilterString();
		props.dataObject.filterObject.apply();
		props.dataObject.load();
	}
}

function loadDataObject() {
	applySearchString();
	props.dataObject?.load();
}

const resizeObserver = new ResizeObserver(_ => {
	handleScroll();
});

let endScrolling = null;

function handleScroll() {
	if (props.dataObject.dynamicLoading) {
		if (viewport?.value && viewport.value.scrollTop + 6 * viewport.value.clientHeight > viewport.value.scrollHeight) {
			if (endScrolling) { clearTimeout(endScrolling); }
			endScrolling = setTimeout(async () => {
				loading.value = true;
				await props.dataObject.dynamicLoading.loadNextPage();
				loading.value = false;
			}, 5);
		}
	}
}

function setCurrentItem(item) {
	if (props.multiselect) {
		item.isSelected = !item.isSelected;
	}

	selectedItem.value = item;
	emit('setCurrentItem', item);
	props.dataObject.setCurrentIndex(item.index);
}


onMounted(() => {
	resizeObserver.observe(viewport.value);
	let scrollDebounce: any = null;
	viewport.value.addEventListener('scroll', (e: any) => {
		if (scrollDebounce) { window.clearTimeout(scrollDebounce); }
		scrollDebounce = window.setTimeout(() => {
			scrollDebounce = null;
			handleScroll(e);
		}, 100);
	});
});
</script>

<template>
	<OMediaQueryProvider>
		<OMatchMedia v-slot="{ isLandscape }">
			<div class="d-flex flex-column h-100 position-relative">
				<div class="d-flex input-group mb-2"
					v-if="!noSearch || !noFilter || !noSort || $slots.customSearchControls">
					<slot name="customSearchControls"></slot>
					<SearchInput class="form-control-sm form-control" @onSearch="search" v-if="!noSearch"
						:mask="props.searchMask" :slotChar="props.searchMaskPlaceholder" />
					<button data-bs-toggle="offcanvas" data-bs-target="#offcanvas-filters"
						class="bi bi-funnel-fill btn btn-outline-secondary" v-if="!noFilter"></button>
					<button data-bs-toggle="offcanvas" data-bs-target="#offcanvas-sort"
						class="bi bi-sort-down btn btn-outline-secondary" v-if="!noSort"></button>

					<div class="offcanvas d-flex" :class="isLandscape ? 'offcanvas-start' : 'offcanvas-bottom h-75'"
						tabindex="-1" id="offcanvas-filters" v-if="!noFilter">
						<div class="offcanvas-body p-0 d-flex flex-column w-100 h-100" style="overflow:unset;">
							<ActiveFilters :dataObject="dataObject"></ActiveFilters>
						</div>
					</div>

					<div class="offcanvas" :class="isLandscape ? 'offcanvas-start' : 'offcanvas-bottom h-75'" tabindex="-1"
						id="offcanvas-sort" v-if="!noSort">
						<div class="offcanvas-header">
							<div>
								Sort
							</div>
						</div>

						<div class="offcanvas-body">
							<OSort :dataObject="dataObject"></OSort>
						</div>
					</div>
				</div>

				<div ref="viewport" class="flex-grow-1 overflow-auto" @scroll="handleScroll">
					<div v-for="categoryRows in itemCategories">
						<div class="category-header" v-if="getItemCategory?.(categoryRows[0])">
							{{ getItemCategory(categoryRows[0]) }}</div>
						<div class="cardview-container">
							<template v-for="(row, index) in categoryRows" :key="row.PrimKey ?? row.ID ?? index">
								<o-card :item="row" :columns="columns" :headerColumns="headerColumns"
									:linkParams="linkParams" :showImages="showImages"
									:imagesView="imagesView"
									:imagesPrimKeyColumnName="imagesPrimKeyColumnName"
									@setCurrentItem="setCurrentItem($event)" :noInfoAction="noInfoAction"
									class="flex-grow-1" :class="rowClass?.(row)"
									:isCurrent="highlightCurrentRow && dataObject.currentIndex == row.index"
									:multiselect="multiselect"
									:imageUrlParameters="imageUrlParameters">
									<template v-for="(index, name) in slots" v-slot:[name]="data">
										<slot :name="name" v-bind="data ?? {}" />
									</template>
								</o-card>
							</template>

							<div class="loading-spinner text-primary"
								v-if="dataObject.state.isNextPageLoading || dataObject.state._isLoading">
								<div class="spinner-border " role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</div>

							<template v-if="!noInfoAction">
								<div class="offcanvas pt-5"
									:class="isLandscape ? 'offcanvas-start' : 'offcanvas-bottom h-75'" tabindex="-1"
									:id="'offcanvas-info'">
									<div class="offcanvas-header">
										<div v-if="selectedItem && headerDataColumns?.columns?.length">
											<div v-for="(column, colIndex) in headerDataColumns.columns" class="card-text">
												<OCardBodyCell :col="column" :row="selectedItem" :colIndex="colIndex"
													:is-active-edit-cell="false" :showFull="true" />
											</div>
										</div>
										<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
											aria-label="Close"></button>
									</div>
									<div class="offcanvas-body">
										<div v-if="selectedItem" v-for="(column, colIndex) in dataColumns.columns"
											class="card-text">
											<OCardBodyCell :col="column" :row="selectedItem" :colIndex="colIndex"
												:is-active-edit-cell="false" :showFull="true" />
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
					
					<div @click="emit('onCreateNew')" class="position-absolute bottom-0 end-0 m-3"
						v-if="enableAddNew">
						<div class="btn btn-primary p-3 rounded-circle d-flex align-items-center justify-content-center shadow"
							style="height:56px; width:56px;">
							<i class="bi bi-plus text-white" style="font-size: 54px;"></i>
						</div>
					</div>
				</div>
			</div>
		</OMatchMedia>
	</OMediaQueryProvider>
</template>

<style scoped>
.cardview-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(v-bind(cardMinWidth), 1fr));
	grid-auto-rows: v-bind(gridAutoRows);
	grid-column-gap: v-bind(cardGap);
	grid-row-gap: v-bind(cardGap);
	position: relative;
	min-height: 50px;
}

.selected {
	background-color: rgba(var(--bs-primary-rgb), .5);
}

.category-header {
	background-color: #aaa;
	color: #000;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 5px
}

.category-header:not(:first-child) {
	margin-top: 5px;
}

.loading-spinner {
	position: absolute;
	bottom: 5px;
	left: 50%;
}
</style>